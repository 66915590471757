import React from 'react';

import { Accordion, AccordionItem } from 'components/Accordion';

import styles from './Faq.module.scss';

export interface Question {
  question: string;
  answer: string;
}

export interface Topic {
  title: string;
  questions: Question[];
}

interface Props {
  title: string;
  questions: Topic[];
}

export const Faq: React.FC<Props> = ({ title, questions }) => (
  <div className={styles.wrapper}>
    <h2>{title}</h2>
    <Accordion isInverted>
      {questions.map((topic) => (
        <AccordionItem title={topic.title} key={topic.title}>
          {topic.questions.map((question) => (
            <p key={question.question}>
              <strong>{question.question}</strong> {question.answer}
            </p>
          ))}
        </AccordionItem>
      ))}
    </Accordion>
  </div>
);
